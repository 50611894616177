<template>
  <div class="btn-box" :style="mergeStyle" @click="handleClick" @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave">
    <LoadingOutlined v-if="loading" class="loading"/>
    <slot name="prefixIcon" v-if="slots['prefixIcon']"> </slot>
    <div v-else>
      <img :src="iconAi" class="prefix-icon" v-if="showPrefixIcon" />
    </div>
    <slot></slot>
    <slot name="suffixIcon" v-if="slots['suffixIcon']"></slot>
  </div>
</template>

<script setup>
import iconAi from "@/assets/common-icons/icon_ai.svg";
import {
  defineProps,
  computed,
  useSlots,
  defineEmits,
  reactive,
} from "vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { object } from "vue-types";
const emits = defineEmits(["click"]);

const slots = useSlots();
const props = defineProps({
  btnWidth: {
    type: String,
    default: "100%",
  },
  btnHeight: {
    type: String,
    default: "100%",
  },

  bthBackGround: {
    type: String,
    default: "red",
  },

  btnColor: {
    type: String,
    default: "#fff",
  },

  customStyle: {
    type: Object,
    default: () => {
      return null;
    },
  },

  borderRadius: {
    type: String,
  },

  showPrefixIcon: {
    type: Boolean,
    default: false,
  },

  showSuffixIcon: {
    type: Boolean,
    default: false,
  },

  borderStyle: {
    type: String,
    default: "",
  },
  activeColor: {
    type: String,
    default: "",
  },

  disabled: {
    type: Boolean,
    default: false,
  },
  disabledStyle: {
    type: object,
    default: () => {
      return null
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const btnStyle = computed(() => {
  const style = {
    width: props.btnWidth,
    height: props.btnHeight,
    background: props.bthBackGround,
    color: props.btnColor,
    border: props.borderStyle,
    "border-radius": props.borderRadius,
  };

  if (props.disabled) {
    const innerDisabledStyle = {
      ...style,
      ...props.customStyle,
      border: "1px solid #D0D0D0",
      color: "#FFFFFF",
      cursor: "not-allowed",
      background: "rgb(208, 208, 208)",
    }
    if (!props.disabledStyle) {
      return innerDisabledStyle
    }

    return {
      ...innerDisabledStyle,
      ...props.disabledStyle
    }
  }

  if (props.customStyle) {
    return { ...style, ...props.customStyle };
  }

  return style;
});

const activeStyle = reactive({});

const mergeStyle = computed(() => {
  if (JSON.stringify(props.activeStyle) != "{}") {
    return { ...btnStyle.value, ...activeStyle }
  } else {
    return btnStyle.value;
  }
});

const handleClick = () => {
  if (props.disabled) {
    return;
  }
  emits("click");
};

const handleMouseEnter = () => {
  if (props.disabled) {
    return;
  }
  if (props.activeColor) {
    activeStyle.background = props.activeColor;
  }
};

const handleMouseLeave = () => {
  if (props.disabled) {
    return;
  }
  if (props.activeColor) {
    activeStyle.background = props.customStyle.background;
  }

};
</script>

<style lang="less" scoped>
.btn-box {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 0;
}

.prefix-icon {
  width: 24.18px;
  height: 24.18px;
  margin-right: 13.46px;
}

.loading {
  position: absolute;
  left: 35px; 
}
</style>
