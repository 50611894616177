"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["width", "height"];
var vue_3 = require("vue");
var use_matting_board_1 = require("../matting-board/composables/use-matting-board");
var dom_helper_1 = require("../matting-board/helpers/dom-helper");
var drawing_helper_1 = require("../matting-board/helpers/drawing-helper");
var blur_1 = require("@/utils/blur");
exports.default = vue_1.defineComponent({
    __name: 'index',
    props: {
        imageFile: {
            type: File,
            default: null,
        },
        srcData: {
            type: ImageData,
        },
        bgData: {
            type: ImageData,
        },
        imageRect: {
            type: Object,
        },
        showOpacityBg: {
            type: Boolean,
            default: true,
        },
        isModelDone: {
            type: Boolean,
            default: false,
        },
        currentRatioItem: {
            type: Object,
            default: function () {
                return {};
            },
        },
        isCanDraggle: {
            type: Boolean,
            default: false,
        },
        canAbleMoveDistance: {
            type: Object,
            default: function () {
                return {};
            },
        },
        canAbleMoveDisProps: {
            type: Object,
            default: function () {
                return {};
            },
        },
        updateBg: {
            type: Boolean,
            default: false,
        },
        currentBlurValue: {
            type: Number,
            default: null,
        },
        originItem: {
            type: Object,
            default: function () {
                return null;
            },
        },
        currentContainerSize: String
    },
    emits: ["drawDown", "cropImageSize", "changePosition"],
    setup: function (__props, _a) {
        var _this = this;
        var _b, _c;
        var expose = _a.expose, emits = _a.emit;
        var props = __props;
        var handleClearCanvas = function () {
            var imageCvs = imageCvsRef.value;
            var imageCtx = imageCvs.getContext("2d");
            dom_helper_1.clearCanvas(imageCtx);
        };
        expose({
            getImageCanvas: getImageCanvas,
            handleClearCanvas: handleClearCanvas
        });
        var imageBoxRef = vue_3.ref(null);
        var imageCvsRef = vue_3.ref(null);
        var imageTest = vue_3.ref();
        var cvsWidth = vue_3.ref(0);
        var cvsHeight = vue_3.ref(0);
        var isMouseDown = vue_3.ref(false);
        var mousePosition = vue_3.ref({ x: 0, y: 0 });
        var scaleRatio = vue_3.ref(1);
        var originCanAbleMoveDis = vue_3.ref({});
        var canAbleMoveDis = vue_3.ref({});
        var canAbleMoveX = vue_3.ref(((_b = props.canAbleMoveDistance) === null || _b === void 0 ? void 0 : _b.sx) * scaleRatio.value);
        var canAbleMoveY = vue_3.ref(((_c = props.canAbleMoveDistance) === null || _c === void 0 ? void 0 : _c.sy) * scaleRatio.value);
        var currentImagePosition = vue_3.ref({ x0: 0, y0: 0 });
        vue_3.onMounted(function () {
            initContextsAndSize();
        });
        vue_3.watch(function () { return props.currentContainerSize; }, function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!props.currentContainerSize) return [3, 2];
                        return [4, vue_3.nextTick()];
                    case 1:
                        _a.sent();
                        initContextsAndSize();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        }); });
        function copyImageData(imagedata) {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            canvas.width = imagedata.width;
            canvas.height = imagedata.height;
            ctx.putImageData(imagedata, 0, 0);
            var newImageData = ctx.getImageData(0, 0, imagedata.width, imagedata.height);
            return newImageData;
        }
        function initContextsAndSize() {
            var _this = this;
            var imageBox = imageBoxRef.value;
            var imageCvs = imageCvsRef.value;
            var imageCtx = imageCvs.getContext("2d");
            vue_3.watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
                var validImageSize, clientWidth, clientHeight, bgImageData, data;
                var _a, _b, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            if (!props.srcData) return [3, 4];
                            validImageSize = {};
                            clientWidth = imageBox.clientWidth;
                            clientHeight = imageBox.clientHeight;
                            if (props.srcData.width < clientWidth &&
                                props.srcData.height < clientHeight) {
                                validImageSize = {
                                    width: props.srcData.width,
                                    height: props.srcData.height,
                                    imageScaleRatio: 1,
                                };
                            }
                            else {
                                validImageSize = use_matting_board_1.computeValidImageSize(props.srcData, { width: clientWidth, height: clientHeight }, { horizontal: 0, vertical: 0 });
                            }
                            cvsWidth.value = validImageSize.width;
                            cvsHeight.value = validImageSize.height;
                            scaleRatio.value = validImageSize.imageScaleRatio;
                            if (props.canAbleMoveDistance && validImageSize.imageScaleRatio) {
                                canAbleMoveDis.value = {
                                    xLeft: ((_a = props.canAbleMoveDistance) === null || _a === void 0 ? void 0 : _a.sx) * scaleRatio.value,
                                    xRight: ((_b = props.canAbleMoveDistance) === null || _b === void 0 ? void 0 : _b.sx) * scaleRatio.value,
                                    yTop: ((_c = props.canAbleMoveDistance) === null || _c === void 0 ? void 0 : _c.sy) * scaleRatio.value,
                                    yBottom: ((_d = props.canAbleMoveDistance) === null || _d === void 0 ? void 0 : _d.sy) * scaleRatio.value,
                                };
                            }
                            if (props.canAbleMoveDisProps) {
                                canAbleMoveDis.value = props.canAbleMoveDisProps;
                            }
                            dom_helper_1.clearCanvas(imageCtx);
                            if (!props.bgData) return [3, 2];
                            bgImageData = null;
                            bgImageData = props.bgData;
                            if (props.currentBlurValue) {
                                data = copyImageData(props.bgData);
                                bgImageData = blur_1.gaussBlur(data, data.width, data.height, props.currentBlurValue);
                                props.originItem.bgImageData = bgImageData;
                            }
                            return [4, updateBgImage(bgImageData)];
                        case 1:
                            _e.sent();
                            _e.label = 2;
                        case 2: return [4, updateSrcImage(props.srcData)];
                        case 3:
                            _e.sent();
                            if (props.isModelDone) {
                                emits("drawDown", true);
                            }
                            _e.label = 4;
                        case 4: return [2];
                    }
                });
            }); });
        }
        function updateBgImage(bgData) {
            return __awaiter(this, void 0, void 0, function () {
                var imageCvs, imageCtx, bgBitmap;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            imageCvs = imageCvsRef.value;
                            imageCtx = imageCvs.getContext("2d");
                            return [4, createImageBitmap(bgData)];
                        case 1:
                            bgBitmap = _a.sent();
                            drawing_helper_1.renderCoverBgImageInCanvas(bgBitmap, imageCtx);
                            return [2];
                    }
                });
            });
        }
        function updateSrcImage(srcData, option) {
            return __awaiter(this, void 0, void 0, function () {
                var imageCvs, imageCtx, srcBitmap;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            imageCvs = imageCvsRef.value;
                            imageCtx = imageCvs.getContext("2d");
                            return [4, createImageBitmap(srcData)];
                        case 1:
                            srcBitmap = _a.sent();
                            if (props.imageRect) {
                                imageCtx.drawImage(srcBitmap, props.imageRect.x * scaleRatio.value * 2, props.imageRect.y * scaleRatio.value * 2, props.imageRect.width * scaleRatio.value * 2, props.imageRect.height * scaleRatio.value * 2);
                            }
                            else {
                                imageCtx.drawImage(srcBitmap, 0, 0, imageCtx.canvas.width, imageCtx.canvas.height);
                            }
                            return [2];
                    }
                });
            });
        }
        function getImageCanvas() {
            return imageCvsRef.value;
        }
        var updatePos = function (offsetX, offsetY) {
            if (!props.canAbleMoveDistance) {
                return;
            }
            var currentX = 0;
            var currentY = 0;
            if (offsetX === 0 && offsetY === 0) {
                return;
            }
            if (offsetX != 0) {
                if (offsetX > 0 && offsetX > canAbleMoveDis.value.xRight) {
                    currentX = canAbleMoveDis.value.xRight;
                }
                else if (offsetX < 0 && Math.abs(offsetX) > canAbleMoveDis.value.xLeft) {
                    currentX = -canAbleMoveDis.value.xLeft;
                }
                else {
                    currentX = offsetX;
                }
            }
            if (offsetY != 0) {
                if (offsetY > 0 && offsetY > canAbleMoveDis.value.yBottom) {
                    currentY = canAbleMoveDis.value.yBottom;
                }
                else if (offsetY <= 0 && Math.abs(offsetY) > canAbleMoveDis.value.yTop) {
                    currentY = -canAbleMoveDis.value.yTop;
                }
                else {
                    currentY = offsetY;
                }
            }
            currentImagePosition.value = {
                x0: currentX,
                y0: currentY,
            };
            emits("changePosition", {
                canAbleMoveDis: canAbleMoveDis.value,
                currentImagePosition: currentImagePosition.value,
            });
            var xLeft = currentX > 0
                ? canAbleMoveDis.value.xLeft + currentX
                : canAbleMoveDis.value.xLeft - Math.abs(currentX);
            var xRight = currentX >= 0
                ? canAbleMoveDis.value.xRight - currentX
                : canAbleMoveDis.value.xRight + Math.abs(currentX);
            var yTop = currentY >= 0
                ? canAbleMoveDis.value.yTop + currentY
                : canAbleMoveDis.value.yTop - Math.abs(currentY);
            var yBottom = currentY >= 0
                ? canAbleMoveDis.value.yBottom - currentY
                : canAbleMoveDis.value.yBottom + Math.abs(currentY);
            canAbleMoveDis.value = {
                xLeft: xLeft,
                xRight: xRight,
                yTop: yTop,
                yBottom: yBottom,
            };
        };
        var handleMouseDown = function (e) {
            if (!props.isCanDraggle) {
                return;
            }
            isMouseDown.value = true;
            var x = e.offsetX;
            var y = e.offsetY;
            mousePosition.value = {
                x: x,
                y: y,
            };
        };
        var handleMouseMove = function (e) {
            if (!props.isCanDraggle) {
                return;
            }
            if (!isMouseDown.value) {
                return;
            }
            var x = e.offsetX;
            var y = e.offsetY;
            var offsetX = x - mousePosition.value.x;
            var offsetY = y - mousePosition.value.y;
            updatePos(offsetX, offsetY);
        };
        var handleMouseup = function (e) {
            isMouseDown.value = false;
            mousePosition.value = null;
        };
        var handleMouseout = function (e) {
            isMouseDown.value = false;
            mousePosition.value = null;
        };
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                class: "image-board-box",
                ref_key: "imageBoxRef",
                ref: imageBoxRef
            }, [
                vue_2.createElementVNode("canvas", {
                    id: "board-canvas",
                    class: vue_2.normalizeClass({ 'board-canvas': __props.showOpacityBg, 'cursor-pointer': __props.isCanDraggle }),
                    ref_key: "imageCvsRef",
                    ref: imageCvsRef,
                    width: cvsWidth.value * 2,
                    height: cvsHeight.value * 2,
                    style: vue_2.normalizeStyle({
                        width: cvsWidth.value + 'px',
                        height: cvsHeight.value + 'px',
                    }),
                    onMousemove: handleMouseMove,
                    onMousedown: handleMouseDown,
                    onMouseup: handleMouseup,
                    onMouseout: handleMouseout
                }, null, 46, _hoisted_1)
            ], 512));
        };
    }
});
